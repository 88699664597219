<template>
  <main-menu />
  <main-nav />
  <treatmentsBlock />
  <pictureBlock />
  <textBlock />
  <InstagramFeed />
  <OpeningHours />
  <CarouselImages />
  <ContactBlock />
</template>

<script>
import MainNav from "@/components/MainNav.vue";
import MainMenu from "@/components/MainMenu.vue";
import TreatmentsBlock from "@/components/TreatmentsBlock.vue";
import PictureBlock from "@/components/PictureBlock.vue";
import TextBlock from "@/components/TextBlock.vue";
import CarouselImages from "@/components/ImagesBlock.vue";
import ContactBlock from "@/components/ContactBlock.vue";
import InstagramFeed from "@/components/InstagramFeed.vue";
import OpeningHours from "@/components/OpeningHours.vue";

export default {
  name: "App",
  components: {
    MainNav,
    MainMenu,
    TreatmentsBlock,
    PictureBlock,
    TextBlock,
    CarouselImages,
    ContactBlock,
    InstagramFeed,
    OpeningHours,
  },
};
</script>
