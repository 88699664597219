<template>
  <div class="section" id="socials">
    <div class="container">
      <div class="row gy-4 align-items-center">
        <div class="col-6">
          <h3 class="fs-2">
            <span>S</span>
            <span>o</span>
            <span>c</span>
            <span>i</span>
            <span class="text__outlined">a</span>
            <span class="text__outlined">l</span>
            <span class="text__outlined">s</span>
          </h3>
        </div>
        <div class="col-5 col-lg-2 ms-auto">
          <div class="d-flex justify-content-between align-items-center">
            <a
              href="https://www.tiktok.com/@samanthahairhouse/"
              class="social_link"
            >
              <font-awesome-icon icon="fa-brands fa-tiktok" />
            </a>
            <a
              href="https://www.facebook.com/samanthahairhouse"
              class="social_link"
            >
              <font-awesome-icon icon="fa-brands fa-facebook-f" />
            </a>
            <a
              href="https://www.instagram.com/samanthahairhouse/"
              class="social_link"
            >
              <font-awesome-icon icon="fa-brands fa-instagram" />
            </a>
          </div>
        </div>
      </div>
    </div>

    <InstagramFeed
      accessToken="IGQVJWV0pZAUHlHdXg2VTduaFVoRGNiX3ZArLWFxaEdPZAnUwREMyUkxVZA19HUFdoZAkFWUTY5Y09iOE9aQ2ducWhrLUhGUjZAFVGpUbGpHaWJNOXg0OEk5V25iMkl5Q0NIR0pGLXJId3UyVEdqdjRRM1puZAgZDZD"
      :count="6"
      :pagination="false"
      :caption="true"
    />
  </div>
</template>
