<template>
  <section class="section section--large" id="over-hairhouse">
    <div class="container">
      <div class="row justify-content-center align-items-center">
        <div class="col-lg-10">
          <div class="row">
            <div class="col-lg-6" data-aos="fade-up">
              <h2>
                {{ title }} <span class="text__outlined">{{ subtitle }}</span>
              </h2>
            </div>
            <div class="col-lg-5" data-aos="fade-up">
              <p>{{ text_block_1 }}</p>
              <p>{{ text_block_2 }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "textBlock",
  data() {
    return {
      title: "Over",
      subtitle: "hairhouse",
      text_block_1:
        "Samantha’s hairhouse is een kapsalon met een huiselijke sfeer. Ik streef ernaar om altijd de beste kwaliteit te leveren, telkens weer opnieuw. Ook de persoonlijke aandacht vind ik heel belangrijk. Dat geeft jou het gevoel dat er naar je geluisterd wordt en je de aandacht krijgt die je verdient bij iedere behandeling. Als mensen een glimlach op hun gezicht krijgen word ik daar ook heel vrolijk van, en krijg ik er nog meer energie van.",
      text_block_2:
        "Het lijkt mij leuk je gauw te ontmoeten in mijn gezellige en warme salon. Heb je vragen of wil je meer informatie? Stuur gerust een berichtje, dan bespreken we samen de mogelijkheden.",
    };
  },
};
</script>
