<template>
  <div class="section section--large" id="openingstijden">
    <div class="container">
      <div class="row flex-wrap">
        <div class="col-12">
          <h3
            class="fs-1 text-center pb-4 mb-4 title justify-content-center"
            data-aos="fade-up"
          >
            <span>O</span>
            <span>p</span>
            <span>e</span>
            <span>n</span>
            <span>i</span>
            <span>n</span>
            <span>g</span>
            <span>s</span>
            <span class="text__outlined">t</span>
            <span class="text__outlined">i</span>
            <span class="text__outlined">j</span>
            <span class="text__outlined">d</span>
            <span class="text__outlined">e</span>
            <span class="text__outlined">n</span>
          </h3>
        </div>

        <div class="col-11 col-md-4 mx-auto">
          <ul class="list-hours">
            <li class="list__item" data-aos="fade-up">
              <span>Maandag:</span>
              <span>Gesloten</span>
            </li>

            <li class="list__item" data-aos="fade-up">
              <span>Dinsdag:</span>
              <span>09:00 - 16:00</span>
            </li>

            <li class="list__item" data-aos="fade-up">
              <span>Woensdag:</span>
              <span>09:00 - 16:00</span>
            </li>

            <li class="list__item" data-aos="fade-up">
              <span>Donderdag:</span>
              <span>09:00 - 16:00</span>
            </li>

            <li class="list__item" data-aos="fade-up">
              <span>Vrijdag:</span>
              <span>09:00 - 16:00</span>
            </li>

            <li class="list__item" data-aos="fade-up">
              <span>Zaterdag:</span>
              <span>09:00 - 15:00</span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "OpeningHours",
};
</script>
