import { createApp } from "vue";
import App from "@/App.vue";

import BootstrapVue3 from "bootstrap-vue-3";
import InstagramFeed from "vue3-instagram-feed";
import "vue3-instagram-feed/dist/style.css";
import "./scss/app.scss";

import AOS from "aos";
import "aos/dist/aos.css";

import { library } from "@fortawesome/fontawesome-svg-core";

/* import font awesome icon component */
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

/* import specific icons */
import {
  faTiktok,
  faFacebookF,
  faInstagram,
  faWhatsapp,
} from "@fortawesome/free-brands-svg-icons";

/* import specific icons */
import { faCalendarCheck } from "@fortawesome/free-regular-svg-icons";

library.add(faTiktok, faFacebookF, faInstagram, faWhatsapp, faCalendarCheck);

const app = createApp(App);
app.use(AOS.init());
app.use(BootstrapVue3);
app.use(InstagramFeed);
app.component("font-awesome-icon", FontAwesomeIcon);
app.mount("#app");
