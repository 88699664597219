<template>
  <header>
    <div class="container position-relative">
      <ul class="menu">
        <li
          class="menu__item"
          v-for="(item, index) in items"
          v-bind:key="index"
        >
          <a class="menu__link" v-bind:href="`#${item.itemTarget}`">{{
            item.menuTitle
          }}</a>
        </li>
      </ul>
    </div>
  </header>
</template>

<script>
export default {
  name: "MainMenu",
  data() {
    return {
      items: [
        { menuTitle: "Home", itemTarget: "home" },
        { menuTitle: "Behandelingen", itemTarget: "behandelingen" },
        { menuTitle: "Over hairhouse", itemTarget: "over-hairhouse" },
        { menuTitle: "Socials", itemTarget: "socials" },
        { menuTitle: "Openingstijden", itemTarget: "openingstijden" },
        { menuTitle: "Contact", itemTarget: "contact" },
      ],
    };
  },
};
</script>
