<template>
  <section class="section">
    <div class="container-fluid px-0">
      <div class="row g-0">
        <div
          class="col-lg-6"
          v-for="(image, index) in images"
          v-bind:key="index"
        >
          <div
            class="image-container"
            data-aos="zoom-in"
            :class="index == 0 ? 'image-container-offset' : ''"
          >
            <span class="image-container__reveal" data-image-reveal></span>
            <img v-bind:src="image.url" alt="{{ image.title }}" />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "pictureBlock",
  data() {
    return {
      images: [
        {
          url: require("@/assets/kapsalon-1.jpg"),
          title: "Samantha aan het werk in de kapsalon",
        },
        {
          url: require("@/assets/kapsalon-2.jpg"),
          title: "Foto van de kapsalon",
        },
      ],
    };
  },
};
</script>
